:root {
  --max-width: 1100px;
  --border-radius: 12px;

  --white: #f9f9f9;
  --white100: #d9d9d9;
  --blue: #000954;
  --orange: #ef5b25;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background: var(--white);
  font-family: "Poppins", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.navAncors button {
  color: var(--white);
  font-size: 1.2rem;
  background: none;
  border: none;

  transition: 0.2s;
}

.navAncors button:hover {
  color: var(--orange);
}

.boxAncor {
  font-size: 1.2rem;
  color: var(--white);
  background: none;
  border: none;
  margin-bottom: 1rem;
}

.boxAncor:hover {
  color: var(--orange);
}

.video {
  border: none;
}

.assembleImg {
  width: 50px;
}

.familyPicture {
  width: 100vw;
}

.aboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.aboutUs p {
  width: 90%;
  text-align: justify;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.aboutUs h1 {
  color: var(--orange);
  margin-bottom: 1rem;
}

.ourHistoryTitle {
  color: var(--orange);
  text-align: center;
  margin-bottom: 2rem;
}

.videoSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.videoContainer p {
  margin-bottom: 2rem;
}

.videoContainer h1 {
  text-align: center;
}

.videoContainer video {
  width: 90%;
}

/* .videoContainer {
  display: flex;
  width: 80%;
  flex-direction: row;
  gap: 5rem;
  font-size: 1.5rem;
} */

@media (min-width: 769px) {
  .hamburger {
    display: none;
  }

  .navAncors button {
    margin-left: 1.5rem;
  }

  .navAncors {
    justify-content: flex-start;
  }

  .header {
    background: rgba(0, 0, 0, 0);
  }

  .budgetButton {
    width: 500px;
  }

  .serviceCardsContainer {
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 5%;
  }
}

@media (min-width: 768px) {
  .videoContainer {
    display: flex;
    width: 80%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 5rem;
    font-size: 1.5rem;
  }

  .videoContainer video {
    width: 35%;
  }
}

@media (max-width: 768px) {
  .navAncors {
    display: none;
  }
  .serviceCardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .budgetButton {
    width: 90%;
    margin-top: 1.5rem;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
}

.header {
  background: var(--blue);
  width: 100%;
  display: flex;
  padding: 1.56rem 0;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: fixed;
  margin-bottom: 5rem;
  z-index: 11;
}

.header a {
  color: var(--white);
  transition: 0.2s;
}

.header a:hover {
  color: var(--orange);
}

.navBar {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heroSection {
  color: var(--white);
  background-color: var(--blue);
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 7rem 0 1.6rem 0;
}

.heroSection h1 {
  font-weight: bold;
  margin-bottom: 1.6rem;
}

.heroSection span {
  color: var(--orange);
}

.budgetButton {
  background-color: var(--white);
  font-size: 1.5rem;
  color: var(--orange);
  font-weight: bold;
  border: none;
  padding: 1rem;
  border-radius: 15px;
}

.budgetButtonDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.budgetButtonDiv :last-child {
  color: var(--blue);
}

.ourServices {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.6rem 0;
  margin-top: 2rem;
}

.ourServices h1 {
  color: var(--orange);
}

.ourServicesTitle {
  font-size: 2rem;
}

.orangeCard {
  width: 90%;
  background-color: var(--orange);
  margin: 3rem 0;
  border-radius: 0.625rem;
  color: var(--white);
  padding: 0 0 1rem 0;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.orangeCard img {
  position: relative;
  height: 85px;
  width: 85px;
  top: -2.5rem;
}

.orangeCard h1 {
  color: var(--white);
  margin-bottom: 1rem;
}

.orangeCard div {
  width: 90%;
}

.blueCard {
  width: 90%;
  background-color: var(--blue);
  margin: 3rem 0;
  border-radius: 0.625rem;
  color: var(--white);
  padding: 0 0 2rem 0;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.blueCard img {
  position: relative;
  top: -2.5rem;
  height: 85px;
  width: 85px;
}

.blueCard h1 {
  color: var(--white);
  margin-bottom: 1rem;
}

.blueCard div {
  width: 90%;
}

.roadmapImg {
  width: 100%;
  height: 1500px;
  background-image: url("../../public/Vantagens-mobile.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.customerCard {
  background-color: var(--blue);
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 3.5rem 0;
  margin-right: 1.5rem;
}

.customerCard p {
  text-align: start;
}

.customersFeedbacksContainer h1 {
  margin: 2rem 0;
  color: var(--blue);
}

.customersFeedbacksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.customersFeedbacks {
  width: 90%;
}

.cardHeader {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.cardHeaderText {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-left: 1rem;
}

.cardPersonName {
  color: var(--white);
  font-weight: 400;
  font-size: 1.4rem;
}

.cardCityName {
  color: var(--white);
  font-size: 1rem;
  font-weight: 400;
}

.cardPicture img {
  height: 5rem;
}

.customerCard p {
  color: var(--white);
  width: 90%;
}

.interestContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  bottom: -4rem;
  z-index: 10;
}

.interestContainer h1 {
  z-index: 10;
  color: var(--orange);
  margin: 4rem 0;
}

.interestCardOrangeLine {
  z-index: 10;
  background-color: var(--orange);
  height: 1rem;
  padding: 0 1.5rem;
  border-radius: 1.25rem 1.25rem 0 0;
}

.interestCard {
  background-color: var(--white);
  z-index: 10;
  width: 90%;
  border-radius: 1.25rem;
  box-shadow: 0.125rem 0.125rem 1.25rem 0.125rem rgba(0, 0, 0, 0.15);
  padding-bottom: 2rem;
}

.interestCard h1 {
  z-index: 10;
  color: var(--blue);
  margin: 2rem 0;
}

.interestCard button {
  z-index: 10;
  color: var(--white);
  width: 80%;
  background-color: var(--orange);
  border: none;
  padding: 1rem 0;
  border-radius: 0.5rem;
}

.footer {
  position: relative;

  padding: 8rem 0 4rem 0;
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: var(--white);
  font-size: 0.8rem;
  text-align: center;
}

.footerInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerLinksContainer {
  width: 70%;
  display: flex;
  padding: 3rem 0;
  justify-content: space-between;
  font-size: 1rem;
  text-align: start;
}

.footerLinksContainer div a {
  transition: 0.2s;
}

.footerLinksContainer div a:hover {
  color: var(--orange);
}

.footerLinksLeftContainer {
  display: flex;
  flex-direction: column;
}

.footerLinksRightContainer {
  display: flex;
  flex-direction: column;
}

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoContainer h1 {
  color: var(--orange);
  margin-bottom: 2rem;
}

@media screen and (min-width: 1360px) {
  .logoSvg {
    height: 3.5rem;
    width: 3.5rem;
  }

  .header a {
    font-size: 1.3rem;
  }

  .heroSection {
    background-color: var(--white);
    background-image: url("../../public/Header.svg");
    background-repeat: no-repeat;
    background-position: bottom;

    background-size: cover;

    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    padding-left: 5%;
  }

  .heroSection h1 {
    font-size: 3rem;
    font-weight: 700;
  }

  .heroSection h3 {
    font-size: 2rem;
    font-weight: 500;
  }

  .budgetButton {
    margin-top: 3rem;
    padding: 2rem;
    font-size: 1.8rem;
    border-radius: 15px;
  }

  .header {
    background: transparent;

    transition: all 0.1s;
  }

  /* .roadmapImg {
    width: 100%;
    height: 140vh;
    background-image: url("../public/Vantagens.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  } */

  .videoSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;
  }

  .customersFeedbacksContainer {
  }

  .navBar {
    justify-content: flex-start;
  }

  .orangeCard {
    width: 35%;
  }

  .blueCard {
    width: 35%;
  }
}
